#home {
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  height: auto;
  display: flex;
  align-items: center;
}

.content {
  padding: 0 20px;
}

.content h3 {
  font-size: 45px;
  font-weight: 700;
  color: #333;
  margin-bottom: 25px;
}

.content .line {
  height: 3px;
  width: 40px;
  margin-bottom: 25px;
  background-color: #333;
  display: inline-block;
}

#typewriter .Typewriter {
  display: inline-block;
  color: #7d7789;
  margin-left: 10px;
}

#typewriter span {
  font-size: 25px;
}

#contact-btn {
  width: 160px;
  height: 50px;
  background-color: #333333;
  margin-top: 20px;
}

#contact-btn a {
  line-height: 50px;
  text-align: center;
  width: 100%;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  #home {
    width: 65%;
    right: 0;
    top: 0;
    position: absolute;
    padding-left: 60px;
  }
}
