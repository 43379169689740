.App {
  box-sizing: border-box;
  /* font-family: open sans; */
  /* line-height: 1.7; */
  width: 100%;
  min-height: calc(100vh - 50px);
  color: #7d7789;
  position: relative;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.start {
  opacity: 0;
  transform: translateY(25px);
}

.end {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s;
}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: none;
}
