#sidebar {
  position: fixed;
  top: 50px;
  right: 0px;
  height: 100vh;
  width: 200px;
  z-index: 15;
  background-color: #f5f3f3;
  transition: all 0.3s ease;
}

#sidebar.inactive {
  right: -200px;
}

#inner {
  padding: 70px 20px 20px;
}

#avatar {
  text-align: right;
  margin-bottom: 30px;
}

#avatar img {
  /* width: 70px; */
  height: 70px;
}

#menu-list {
  width: 100%;
}

#menu-list ul {
  list-style-type: none;
  text-align: right;
  color: #333;
}

#menu-list li {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
}

#menu-list li:hover {
  cursor: pointer;
}

#menu-list li.true {
  font-weight: bold;
}
