#main-content {
  /* width: calc(100% - 120px); */
}

#author-image {
  display: none;
}

@media screen and (min-width: 1000px) {
  #author-image {
    margin-top: 50px;
    display: block;
    width: 35%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url(../images/R5__3871.jpg);
    height: calc(100% - 50px);
    margin-left: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
}
