#topbar-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  z-index: 15;
  padding: 0 20px;
}

#topbar-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}

#logo img {
  width: 100px;
  height: 17.45px;
  background: transparent;
}

#hamburger img {
  width: 30px;
  height: 24px;
  display: inline-block;
}

#top-list {
  display: none;
}

@media (min-width: 1000px) {
  #hamburger {
    display: none;
  }

  #top-list {
    display: inline-block;
    color: #333;
  }

  #top-list ul {
    list-style-type: none;
  }

  #top-list li {
    display: block;
    float: left;
    height: 50px;
    line-height: 50px;
    padding: 0px 20px;
  }

  #top-list li:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 1000px) {
  #topbar-outer {
    padding: 0 60px;
  }

  #top-list li {
    transition: 0.5s;
  }

  #top-list li:hover,
  #top-list li:active,
  #top-list li.true {
    background-color: #333;
    color: #fff;
  }
}
