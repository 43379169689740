#contact-section {
  margin-top: 50px;
  padding: 95px 20px 0;
}

#contact-title {
  width: 100%;
  position: relative;
  color: #333;
  letter-spacing: 4px;
  font-weight: 700;
  overflow: hidden;
}

#contact-title span::after {
  content: '';
  width: 5000px;
  height: 1px;
  background-color: #7d7789;
  position: absolute;
  top: 50%;
  margin-left: 15px;
}

#short-info {
  margin-top: 60px;
  width: 100%;
  height: auto;
}

#short-info ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

#short-info li {
  width: 100%;
  margin-bottom: 30px;
}

#short-info .list-inner {
  padding: 25px 15px;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.list-inner img {
  height: 15px;
  width: 15px;
}

.list-inner span {
  margin-top: 10px;
  width: 100%;
  display: inline-block;
  font-size: 20px;
}

@media screen and (min-width: 1000px) {
  #contact-section {
    position: absolute;
    right: 0px;
    padding-left: 70px;
    width: 65%;
  }
}
